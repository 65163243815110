import React, { useState, useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import Layout from '../components/Layout';
import '../styles/index.scss';
import useSearch from '../hooks/useSearch';

function PageNotFound() {
  const [isLoading, setLoading] = useState(true);
  const [handleClick] = useSearch();
  const currentPage =
    typeof window !== 'undefined' ? window.location.pathname : '';

  useEffect(() => {
    if (currentPage) {
      const category = currentPage.split('/');
      const redirectUrl = category?.slice(0, -1)?.join('/');
      if (redirectUrl.match(/events/)) {
        navigate('/events')
      } else {
        setLoading(false);
      }
    }
  }, []);

  if (isLoading) return null

  return (
    <Layout>
      <div className="error-container">
        <div>
          <h2>ØV! Siden blev ikke fundet</h2>
          <Link to="/">
            <p>Tilbage til forsiden</p>
          </Link>
        </div>
      </div>
    </Layout>
  );
}

export default PageNotFound;
